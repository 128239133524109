import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
// import p1 from 'assests/Photos/SchoolProgram/RaEkDevas/1.jpeg';
// import p2 from 'assests/Photos/SchoolProgram/RaEkDevas/2.jpeg';
// import p3 from 'assests/Photos/SchoolProgram/RaEkDevas/3.jpeg';
// import p4 from 'assests/Photos/SchoolProgram/RaEkDevas/4.jpeg';
// import p5 from 'assests/Photos/SchoolProgram/RaEkDevas/5.jpeg';
// import p6 from 'assests/Photos/SchoolProgram/RaEkDevas/6.jpeg';
// import p7 from 'assests/Photos/SchoolProgram/RaEkDevas/7.jpeg';



import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/SchoolProgram/components/SidebarArticles/SidebarArticles';
import HumanityClub from 'views/HumanityClub/HumanityClub';


const RashtriyaEktaDivas = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_archive_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/Humanity/RaEkDevas/1.jpeg`;
  const p2 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/Humanity/RaEkDevas/2.jpeg`;
  const p3 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/Humanity/RaEkDevas/3.jpeg`;
  const p4 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/Humanity/RaEkDevas/4.jpeg`;
  const p5 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/Humanity/RaEkDevas/5.jpeg`;
  const p6 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/Humanity/RaEkDevas/6.jpeg`;
  const p7 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/Humanity/RaEkDevas/7.jpeg`;


  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },
      {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
      },
      {
          src: p7,
          source: p7,
          rows: 1,
          cols: 1,
        },
  


    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Rashtriya Ekta Divas (National Unity Day)
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
     Date: 31 October 2022
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        National Public School, Yeshwanthpur organized a ‘Unity Run’ and  various other activities on 31 October
2022, on the occasion of The Rashtriya Ekta Divas (National Unity Day) to commemorate the birth
anniversary of Sardar Vallabhbhai Patel. A special assembly was held to celebrate National Unity Day. The
assembly commenced with a short poem highlighting the significance of unity and togetherness in our lives.
Thereafter, students presented a skit and mesmerising dance to instil a feeling of oneness and to convey the
message “Where there is unity, there is always victory”. The students and the staff took the pledge of
‘Rashtriya Ekta Divas’ in the morning assembly followed by the students of various classes enthusiastically
participating in the ‘Unity Run’, calling out slogans on unity. This was followed by enlightening speeches by
the Principals and a senior faculty member on the role of Sardar Vallabhbhai Patel in national integration.
The school also facilitated the students and teachers to watch the digital exhibition ‘Sardar Patel – The
Architect of Unification’.<br/><br/>
The students were filled with immense patriotic fervour towards their motherland and they thoroughly
enjoyed the event. These activities helped us to exhibit the inherent strength and resilience of our people
to withstand threats to the security, unity and integrity of the country.
        </Typography>
      </Box>
      <Typography
          variant={'h4'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
        
<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={3}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
             <HumanityClub/>
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default RashtriyaEktaDivas;